@font-face {
  font-display: fallback;
  font-family: 'Oracle Sans';
  font-weight: 300;
  src: url('../fonts/OracleSans_W_Lt.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Oracle Sans';
  font-weight: 400;
  src: url('../fonts/OracleSans_W_Rg.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Oracle Sans';
  font-weight: 500;
  src: url('../fonts/OracleSans_W_SBd.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Oracle Sans';
  font-weight: 600;
  src: url('../fonts/OracleSans_W_Bd.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Oracle Sans';
  font-weight: 700;
  src: url('../fonts/OracleSans_W_Bd.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Oracle Sans';
  font-weight: 800;
  src: url('../fonts/OracleSans_W_XBd.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Oracle Sans';
  font-weight: 900;
  src: url('../fonts/OracleSans_W_XBd.woff') format('woff');
}
