@import 'common';

.bread-crumb {
  display: flex;
  align-items: center;
  background: transparent;
  min-height: auto;
  margin: 0 $content-lr-spacing 1.9rem $content-lr-spacing;
  font-size: $fs-16;
  line-height: $lh-20;
  font-weight: $fw-500;
  color: $neutral-140;
}

.bread-crumb-item {
  display: inline-flex;
  align-items: center;
}

.bread-crumb-link {
  color: $blue-120;
}

.bread-crumb-link:hover {
  @extend .bread-crumb-link;
  text-decoration: none;
}
