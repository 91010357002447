//#region variables

//#region colors

$oracle-red: #c74634;
$black: #000000;
$white: #ffffff;
$white1: #fcfbfa;
$cream1: #fbf9f8;
$light-gray1: #e8e8e7;
$light-gray2: #bcb6b1;
$brand-light-30: #e5f4dc;
$brown: #3a3632;
$brown1: #161513;
$brown2: #312d2a;
$brown3: #404040;
$brown4: #222222;
$brown5: #161513a6;
$brown6: #16151366;
$blue-80: #5fa2ba;
$blue-120: #00688c;
$neutral-90: #8b8580;
$neutral-100: #7a736e;
$neutral-120: #665f5b;
$neutral-140: #514c47;
$neutral-150: #666666;
$app-background-color: #f5f4f2;
$yellow: #fff200;
$app-body-color: #100f0e;
//#endregion

//#region font size

$fs-7: 0.7rem;
$fs-8: 0.8rem;
$fs-10: 1rem;
$fs-11: 1.1rem;
$fs-12: 1.2rem;
$fs-13: 1.3rem;
$fs-14: 1.4rem;
$fs-15: 1.5rem;
$fs-16: 1.6rem;
$fs-18: 1.8rem;
$fs-20: 2rem;
$fs-24: 2.4rem;
$fs-28: 2.8rem;
$fs-32: 3.2rem;
$fs-36: 3.6rem;
$fs-50: 5rem;
$fs-120: 12rem;
//#endregion

//#region font weight

$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

//#endregion

//#region line height

$lh-16: 1.6rem;
$lh-18: 1.8rem;
$lh-20: 2rem;
$lh-22: 2.2rem;
$lh-32: 3.2rem;
$lh-36: 3.6rem;
$lh-44: 4.4rem;
$lh-132: 13.2rem;

//#endregion

//#region others

$content-lr-spacing: 7%;

//#endregion

//#endregion

.pl-10 {
  padding-left: 1rem;
}

.ml-10 {
  margin-left: 1rem;
}

.mr-10 {
  margin-right: 1rem;
}

.mr-15 {
  margin-right: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.button {
  min-width: max-content;
  height: fit-content;
  font-size: $fs-14;
  line-height: $lh-16;
  font-weight: $fw-500;
  border: 0.1rem solid rgba(22, 21, 19, 0.5);
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 1rem 1.6rem;
}

.button-primary {
  @extend .button;
  background: $brown2;
  color: $white;
}

.button-secondary {
  @extend .button;
  background: transparent;
  color: $brown1;
}
