@import 'common';

.dropdown-menu.show {
  min-width: 35rem;
  max-height: 70vh;
  overflow-y: auto;
  border: 0.1rem solid rgba(22, 21, 19, 0.1);
  box-sizing: border-box;
  box-shadow: 0rem 0.8rem 3.2rem -1rem rgba(0, 0, 0, 0.3);
  border-radius: 0.6rem;
}

.dropdown-item.active,
.dropdown-item:hover {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  background-color: $brand-light-30;
  color: $neutral-120;
}

.custom-dropdown-category {
  cursor: pointer;
  color: $brown1;
  font-size: $fs-14;
  font-weight: $fw-500;
  line-height: $lh-16;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  padding: 1rem;
}

.custom-dropdown-category-image {
  margin-right: 0.6rem;
  width: 0.9rem;
  height: 0.9rem;
}

.custom-dropdown-item {
  padding: 1.4rem 2.5rem 1.4rem 2.5rem;
  color: $neutral-120;
  font-size: $fs-14;
  line-height: $lh-16;
  font-weight: $fw-500;
}

.custom-dropdown-toggle {
  display: inline-flex !important;
  justify-content: space-between;
  align-items: center;
  min-height: 2.8rem;
  min-width: 25rem;
  background-color: $white;
  color: $brown1;
  font-size: $fs-14;
  line-height: $lh-16;
  font-weight: $fw-500;
  border: 0.1rem solid rgba(22, 21, 19, 0.5) !important;
}

.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:active,
.custom-dropdown-toggle:not(:disabled),
.custom-dropdown-toggle:not(.disabled):active {
  background-color: $white !important;
  color: $brown1 !important;
  border: 0.1rem solid rgba(22, 21, 19, 0.5) !important;
}

.custom-dropdown-search-bar {
  width: auto;
  margin: 0.5rem;
  align-items: center;
}

.custom-dropdown-search-input {
  height: 4.4rem;
  font-size: $fs-14;
  line-height: $lh-16;
  padding-left: 4rem;
  border: 0.1rem solid rgba(22, 21, 19, 0.5);
  box-sizing: border-box;
  border-radius: 0.4rem;
}

.custom-dropdown-search-icon {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  top: 2.45rem;
  left: 2rem;
}
