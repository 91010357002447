@import 'common';

.error-page {
  padding: 13.2rem;
}

.error-page h1 {
  font-weight: $fw-700;
  font-size: $fs-120;
  line-height: $lh-132;
  color: $black;
}

.error-page h2 {
  font-weight: $fw-900;
  font-size: $fs-36;
  line-height: $lh-44;
  color: $oracle-red;
}

.error-page h3 {
  font-weight: $fw-600;
  font-size: $fs-24;
  line-height: $lh-32;
  color: $brown3;
}

.error-page-link {
  font-weight: normal;
  font-size: $fs-16;
  line-height: $fs-20;
  color: $blue-120;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: max-content;
}

.error-page-link-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: contents;
}

.error-page p {
  font-weight: normal;
  font-size: $fs-14;
  line-height: $fs-16;
  color: $brown4;
  margin: 0;
}

.error-code-section {
  display: flex;
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;
  align-items: center;
}

.error-copy-button {
  border: none;
  background: none;
  font-size: $fs-14;
  line-height: $lh-20;
  font-weight: normal;
  color: $blue-120;
}
