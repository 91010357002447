@import '~bootstrap/scss/bootstrap';
@import 'common';
@import 'fonts';

html {
  font-size: 62.5%;
  overflow-x: hidden;
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Oracle Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $app-body-color;
  background: $app-background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  height: auto;
}

//#region Header

.banner {
  display: flex;
  justify-content: center;
  background: $yellow;
  height: 2.8rem;
  font-size: $fs-16;
  font-weight: $fw-700;
  color: $app-body-color;
  line-height: $lh-20;
  div {
    margin: auto;
  }
}

.header-container {
  width: 94%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.header-top {
  justify-content: stretch;
  background: $brown;
}

.header-status {
  padding: 1.5rem $content-lr-spacing;
  background: $cream1;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  font-weight: $fw-700;
}

.all-status-text {
  color: $brown1;
  vertical-align: middle;
  padding-left: 0.2rem;
  font-size: $fs-28;
  line-height: $lh-36;
}

.header-link-active {
  @extend .header-link;
  color: $neutral-140;
  font-weight: $fw-600;
}

.header-link-inactive {
  @extend .header-link;
  color: $neutral-100;
  font-weight: $fw-500;
}

.header-link {
  padding: 1rem 1rem 1rem 0rem;
  border: none;
  background: transparent;
  font-size: $fs-16;
  line-height: $lh-20;
  align-self: center;
}

.header-link-active:hover {
  text-decoration: none;
  @extend .header-link-active;
}

.header-link-inactive:hover {
  text-decoration: none;
  @extend .header-link-inactive;
}

.signout {
  justify-self: flex-end;
  margin-left: 65%;
  white-space: nowrap;
}

.signout-button {
  color: white;
  border: none;
  font-weight: $fw-600;
  flex-wrap: nowrap;
}

.loading-spinner {
  min-height: 40rem;
  min-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-border {
  height: 4rem;
  width: 4rem;
}

.header-actions {
  display: inline-flex;
}

.legend {
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 1.6rem;
}

.legend-text {
  font-size: $fs-16;
  line-height: $lh-20;
  font-weight: normal;
  color: $brown1;
}

.legend-popover {
  min-width: 32rem;
  font-family: inherit;
  padding: 1.6rem;
  background: $white;
  border-radius: 0.6rem;
  border: 0.1rem solid rgba(22, 21, 19, 0.1);
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.header-button a {
  text-decoration: none;
  color: $white;
}

.logo {
  margin-left: $content-lr-spacing;
}

.page-header-icon {
  margin-right: 1rem;
}

.logo-text {
  margin-left: 0.8%;
  font-size: $fs-18;
  line-height: 2.3rem;
  width: 20%;
  color: $white1;
}

.status-icon {
  width: 2rem;
  height: 2rem;
}

//#endregion

//#region Content
.active-incident-card {
  min-height: auto;
  background: $white;
  margin: 0 $content-lr-spacing 1% $content-lr-spacing;
  padding: 2rem;
  border-radius: 0.6rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.active-incident-content {
  display: flex;
  justify-content: flex-start;
}

.active-incident-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
    color: $brown1;
  }
  h3 {
    color: $brown2;
    font-weight: $fw-500;
    font-size: $fs-16;
    line-height: $lh-20;
  }
  p {
    font-weight: normal;
    font-size: $fs-14;
    line-height: $lh-16;
  }
  button {
    a {
      text-decoration: none;
      color: $brown1;
    }
    a:hover {
      text-decoration: none;
      color: $brown1;
    }
  }
  .incident-impact-item {
    h5 {
      margin-bottom: 0;
      font-weight: normal;
      font-size: $fs-12;
      line-height: $lh-16;
    }
  }
}

main {
  flex: 1 0 auto;
  min-height: auto;
}

.page-header {
  margin-bottom: 1.9rem;
  justify-content: center;
  align-content: center;
}

.page-header-children {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.page-content-container {
  flex: 1 0 auto;
  min-height: auto;
  background: $white;
  margin: 1% $content-lr-spacing 2% $content-lr-spacing;
  border-radius: 0.6rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.content-header-top {
  background: url('../images/pebble.png');
  border-radius: 6px 6px 0px 0px;
  height: 0.6rem;
}

.menu-list {
  background-color: $white;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      display: inline-block;
      padding: 1.2rem 1.8rem;
      font-weight: $fw-500;
    }
    li.menu-item {
      text-align: center;
      color: $brown1;
      font-size: $fs-16;
      line-height: $lh-20;
      color: rgba(22, 21, 19, 0.563);
    }
  }
}

.menu-list ul li.active,
.menu-list ul li:hover {
  color: $brown1;
  background: #ffffff;
  box-shadow: inset 0px -3px 0px #5f7d4f;
}

//#endregion

//#region Footer

.footer {
  bottom: 0;
  margin-top: 0.5rem;
  padding: 0.2rem 0px 0.2rem 0px;
  background: $brown;
  color: $light-gray2;
  font-size: $fs-12;
  line-height: $lh-16;
}

.footer-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4% $content-lr-spacing;
}

.footer-list {
  display: flex;
  list-style: none;
  vertical-align: baseline;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-inline-start: 0;
  li {
    margin-right: 0.8rem;
    display: inline-block;
    a {
      text-decoration: none;
      color: $light-gray2;
    }
  }
}

.footer-nav-separater {
  margin-right: 0.8rem;
}

.contact-us {
  background: url('./../icons/ask-oracle.svg');
  height: 2.4rem;
  width: 2.4rem;
  display: block;
}

//#endregion

.tooltip {
  background: $white;
  padding: 0.3rem 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 5px;
  color: $black;
  font-size: $fs-12;
}

//#region media queries

@media screen and (min-width: 481px) and (max-width: 980px) {
  body {
    font-size: $fs-8;
  }
  .logo-text {
    font-size: $fs-16;
    line-height: $lh-18;
  }
}

@media all and (min-width: 100px) and (max-width: 480px) {
  body {
    font-size: $fs-7;
  }
  .logo-text {
    font-size: $fs-14;
    line-height: $lh-16;
  }
  .menu-list ul li {
    padding: 1rem 1.6rem;
  }

  .menu-list ul li.menu-item {
    font-size: $fs-12;
  }
  .header-status {
    font-size: $fs-20;
    font-weight: $fw-600;
  }
}

//#endregion
