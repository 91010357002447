@import 'common';

$down-arrow: url('/assets/icons/down-arrow.svg');

.incident-impact-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.incidents {
  flex: 1;
  align-items: stretch;
  margin: $fs-18 $fs-24 $fs-24 $fs-24;
}

.incidents h2 {
  font-size: $fs-24;
  line-height: $lh-32;
  font-weight: $fw-600;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.incidents h3 {
  font-size: $fs-18;
  line-height: $lh-20;
  color: $brown1;
  font-weight: normal;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.incidents h4 {
  font-size: $fs-14;
  line-height: $lh-20;
  color: $neutral-120;
  font-weight: $fw-500;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.incident-summary-list-item-heading {
  margin-bottom: 1rem !important;
}

.incidents h5 {
  font-size: $fs-14;
  line-height: $lh-16;
  color: $neutral-150;
  font-weight: normal;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
}

.incidents p {
  font-size: $fs-13;
  line-height: $lh-18;
  color: $brown4;
  font-weight: normal;
}

.month-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.month-filter-text {
  color: $black;
  font-style: normal;
  font-weight: $fw-500;
  font-size: $fs-12;
  line-height: $lh-16;
  text-align: center;
  width: 19rem;
}

.month-filter-label {
  color: $black;
  font-style: normal;
  font-weight: $fw-500;
  font-size: $fs-12;
  line-height: $lh-16;
  margin-right: 0.5rem;
}

.month-filter-arrow {
  cursor: pointer;
}

.incident-summary-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.incident-summary-filter-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.incident-summary-menu-filter {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.incident-summary-menu-label {
  color: $brown1;
  opacity: 0.7;
  font-style: normal;
  font-weight: $fw-500;
  font-size: $fs-12;
  line-height: $lh-16;
  margin-right: 1rem;
}

.incident-summary-monthly-incidents {
  margin-top: $fs-32;
}

.incident-summary-incident {
  margin-bottom: $fs-36;
}

.incident-summary-incident-link {
  font-size: $fs-12;
  line-height: $lh-16;
  color: $blue-80;
  font-weight: normal;
  text-decoration-line: underline;
}

.incident-impact-status {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.incident-impact-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.incident-update-list {
  margin-bottom: 3.6rem;
}

.incident-detail-text {
  color: $brown4 !important;
}

.impact-regions {
  font-size: $fs-13;
  color: $neutral-140 !important;
  line-height: $lh-22 !important;
}
