@import 'common';

.horizontal-Separator {
  height: 1px;
  background: $brown1;
  opacity: 0.1;
}

.region-val {
  width: 6.5rem;
  min-width: 6.5rem;
}

.status-dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: 0 1rem;
}

.service-not-available {
  font-size: $fs-15;
}

.region-catg-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.service-category {
  clip-path: inset(-4px 0px -4px 0px);
  z-index: 1;
  position: relative;
  padding: 0.7rem;
  padding-left: 1.5rem;
  background-color: $light-gray1;
  color: $black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
      rgba(232, 232, 231, 0.97),
      rgba(232, 232, 231, 0.97)
    ),
    url('../images/texture.png');
}

.list {
  max-width: 125.5em;
  max-height: calc(100vh - 3 * 10vh);
  overflow: auto;
  position: relative;
  /* TODO: Kept for some reference */
  /* background-color: white; */
  /* mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 2px, black 2px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 2px, black 2px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition: mask-position 0.3s, -webkit-mask-position 0.3s; */
}

/* .list:hover {
    -webkit-mask-position: left top;
    mask-position: left top;
  } */

.table-container {
  display: flex;
  width: 100%;
}

.status-table {
  flex: 1;
  width: 100%;
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
}

.status-table tr {
  text-align: center;
  vertical-align: middle;
}

.status-table td,
th {
  font-size: $fs-12;
  line-height: $lh-16;
}

.status-table thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: $white;
  height: 5rem;
  font-weight: $fw-500;
  color: $brown5;
}

.status-table thead th {
  z-index: 1;
}

.status-table thead th:first-child {
  left: 0;
  z-index: 2;
  text-align: left;
  padding-left: 1rem;
}

.status-table tbody th {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  left: 0;
  text-align: left;
  vertical-align: middle;
  padding-left: 1rem;
}

.category {
  height: 3rem;
  text-align: left;
  padding-left: 1rem;
  font-size: $fs-16;
  font-weight: bold;
  line-height: $lh-20;
}

.left-heading {
  background: $white;
  font-weight: normal;
  font-size: $fs-14;
  line-height: $lh-16;
}

.status-table-region-header {
  position: relative;
  z-index: 2;
}

.status-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  font-weight: normal;
  font-size: $fs-16;
  line-height: $lh-18;
  color: $oracle-red;
  height: 10vh;
  padding: 0 3rem;
  vertical-align: middle;
}

.healthcheck-error {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 10vh;
  padding: 3rem 9rem;
  vertical-align: middle;
  align-items: stretch;
  transform: translate(0%, 10%);
  min-width: 472px;
}
.healthcheck-headerText {
  font-weight: bolder;
  font-size: $fs-36;
  line-height: $lh-32;
  color: black;
  margin-bottom: 5rem;
  margin-right: 3rem;
}

.healthcheck-subtext-error {
  font-weight: bolder;
  font-size: $fs-32;
  line-height: $lh-32;
  color: $oracle-red;
  margin-bottom: 3rem;
  margin-right: 3rem;
}

.healthcheck-subtext {
  font-weight: bolder;
  font-size: $fs-32;
  line-height: $lh-36;
  margin-bottom: 5rem;
  max-width: 80rem;
  margin-right: 3rem;
}

.healthcheck-link {
  font-weight: 400;
  font-size: $fs-20;
  line-height: $lh-36;
  color: $blue-120;
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
  margin-right: 3rem;
}

.healthcheck-timestamp {
  font-weight: normal;
  font-size: $fs-14;
  line-height: $lh-36;
  margin-bottom: 1rem;
}

@media screen and (min-width: 481px) and (max-width: 1380px) {
  .table-container {
    display: flex;
    max-width: 125.5em;
    max-height: calc(100vh - 3 * 10vh);
    overflow: auto;
    position: relative;
  }
}

@media all and (min-width: 100px) and (max-width: 480px) {
  .table-container {
    display: flex;
    max-width: 125.5em;
    max-height: calc(100vh - 3.8 * 10vh);
    overflow: auto;
    position: relative;
  }
}
